import React from "react"
import Layout from "../components/layout_dimsummenu"
import content from "../../content/menuitems.yaml"
export default () => (
  <>
  <Layout>
  <section id="top"
    style= {{
      padding: '90px 0 72px',
      background: '#F0F0F0',
      textalign: 'center'
    }}>
      <div className="row add-bottom">
        <div className="six columns add-bottom">
          <h1>Dim Sum Menu</h1>
        </div>  
      </div>
  </section>
  
  <section id="dimsumitems1"
    style= {{
      padding: '90px 0 72px',
      background: '#F0F0F0',
      textalign: 'center'
    }}
  >
    <div className="row add-bottom">
      <div className="six columns add-bottom">
        <h2>精美點心</h2>
        <hr/>
            { content.dimsumitems1.map((dimsumitem1, index) =>
            <div className="row add-bottom">
              <div>
                <h5> 
                    <ul>
                        <li>{ dimsumitem1.name } - { dimsumitem1.price } </li>
                        <li>{ dimsumitem1.chinese } </li>
                    </ul>
                </h5>
               </div>
            </div>
             )
          }
      </div>    
    </div>
    </section>

    <section id="dimsumitems2"
    style= {{
      padding: '90px 0 72px',
      background: '#F0F0F0',
      textalign: 'center'
    }}
  >
    <div className="row add-bottom">
      <div className="six columns add-bottom">
        <h2>午市特色煲仔</h2>
        <hr/>
            { content.dimsumitems2.map((dimsumitem2, index) =>
            <div className="row add-bottom">
              <div>
                <h5> 
                    <ul>
                        <li>{ dimsumitem2.name } - { dimsumitem2.price } </li>
                        <li>{ dimsumitem2.chinese } </li>
                    </ul>
                </h5>
               </div>
            </div>
             )
          }
      </div>    
    </div>
    </section>

    <section id="dimsumitems3"
    style= {{
      padding: '90px 0 72px',
      background: '#F0F0F0',
      textalign: 'center'
    }}
  >
    <div className="row add-bottom">
      <div className="six columns add-bottom">
        <h2>精美廚點</h2>
        <hr/>
            { content.dimsumitems3.map((dimsumitem3, index) =>
            <div className="row add-bottom">
              <div>
                <h5> 
                    <ul>
                        <li>{ dimsumitem3.name } - { dimsumitem3.price } </li>
                        <li>{ dimsumitem3.chinese } </li>
                    </ul>
                </h5>
               </div>
            </div>
             )
          }
      </div>    
    </div>
    </section>

    <section id="dimsumitems4"
    style= {{
      padding: '90px 0 72px',
      background: '#F0F0F0',
      textalign: 'center'
    }}
  >
    <div className="row add-bottom">
      <div className="six columns add-bottom">
        <h2>生滾靚粥</h2>
        <hr/>
            { content.dimsumitems4.map((dimsumitem4, index) =>
            <div className="row add-bottom">
              <div>
                <h5> 
                    <ul>
                        <li>{ dimsumitem4.name } - { dimsumitem4.price } </li>
                        <li>{ dimsumitem4.chinese } </li>
                    </ul>
                </h5>
               </div>
            </div>
             )
          }
      </div>    
    </div>
    </section>

    <section id="dimsumitems5"
    style= {{
      padding: '90px 0 72px',
      background: '#F0F0F0',
      textalign: 'center'
    }}
  >
    <div className="row add-bottom">
      <div className="six columns add-bottom">
        <h2>炒粉麵飯</h2>
        <hr/>
            { content.dimsumitems5.map((dimsumitem5, index) =>
            <div className="row add-bottom">
              <div>
                <h5> 
                    <ul>
                        <li>{ dimsumitem5.name } - { dimsumitem5.price } </li>
                        <li>{ dimsumitem5.chinese } </li>
                    </ul>
                </h5>
               </div>
            </div>
             )
          }
      </div>    
    </div>
    </section>
  
</Layout>
  </>
)